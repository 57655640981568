<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col style="text-align: center" cols="10" sm="8" md="6" lg="4">
            <!-- TODO: we need a design for this "store front" page, with feature products, useful links, etc. and also what to show when it's a new store with no content .... we should still show something so it's not a blank page -->
            <!-- TODO: link to view account -->
            <!-- TODO: show featured products; allow organization to select which products are on front page, or set it up with special banners with a page builder, or something like that; and/or we can show links to view account, go to brand's homepage (link from their brandprofile), etc. we don't need to check if uesr is authenticated, because if they try to access a feature that is for authenticated users only they will get redirected to login page; -->
            <!-- TODO: what to show when there are no products? instead of this placeholder statement: -->
            <template v-if="Array.isArray(productList) && productList.length === 0">
                <p>Coming soon!</p>
            </template>
            <!-- TODO: the list of products needs to be styled better; if there's a product image then show the image and product name in a little card maybe -->
            <template v-if="Array.isArray(productList) && productList.length > 0">
                <v-list>
                    <v-list-item v-for="product in productList" :key="product.id" @click="select(product)">
                        <v-list-item-content>
                            {{ product.name }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import LoginCard from '@/components/LoginCard.vue';

export default {
    components: {
        // LoginCard,
    },
    data: () => ({
        productList: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    watch: {
        // isAuthenticatedReady(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.init();
        //     }
        // },
        // // isAuthenticated(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.loadProductList();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.loadProductList();
            }
        },
    },
    methods: {
        // init() {
        //     if (this.isAuthenticated) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        // redirectAuthenticatedUser() {
        //     // TODO: we need to check query parameters, if there's an organization id redirect to the dashboard for that organization, otherwise if there's only one organization, go to that one, or if there's more than one show the organization selection , and if the user doens't have any organizations then show a message that they need to contact the administrator to be added to an organization
        //     this.$router.replace({ name: 'dashboard' });
        // },
        async loadProductList() {
            try {
                this.$store.commit('loading', { loadProductList: true });
                const response = await this.$client.site(this.brandprofile).product.search();
                if (response?.list) {
                    this.productList = response.list;
                }
            } catch (err) {
                console.error('loadProductList failed', err);
            } finally {
                this.$store.commit('loading', { loadProductList: false });
            }
        },
        select(product) {
            if (this.brandselector === 'brandprofile') {
                this.$nav.push({ name: 'brand-catalog-item', query: { id: product.id } });
            } else {
                this.$nav.push({ name: 'main-catalog-item', query: { id: product.id } });
            }
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.brandprofile) {
            this.loadProductList();
        }
    },
};
</script>
